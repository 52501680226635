@media screen and (max-width: 400px) {
    #features {
      padding: 20px;
      width: 100%;
    }
    #header,
    #about,
    #roadmap,
    #specs,
    #team,
    #footer {
      width: 100%;
    }
  }